import React from "react";
import { getAPI } from "../Utils/Api-Interface";
import { useState } from "react";
import Plansmodal from "./Modals/plansModal";
import Restaurantmodal from "./Modals/RestaurantModal";
import SpinnerLogin from "../assets/Spinners/SpinnerLogin";
import { store } from "react-notifications-component";
import Moment from "react-moment";
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';

export default function Table(props) {
  //states have been desgined here.
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal3,setModal3]=useState(false)


   
  // fetches restaurant information modal.
  const sendBid = () => {
    setModal(true);
  };

  //mounts the plans model.
  const plansModal = () => {
    setModal2(true);
  };

  const clearModal=()=>{
    setModal2(false)
  }



  //rejetcs and turns the loader on.
  const reject = () => {
    setLoading(true);

    getAPI(
      `/admin/approve_restaurants?businessId=${props.bid}&planId=0&status=0`,
      localStorage.getItem("auth")
    )
      .then((res) => {
          setLoading(false);
          console.log(res);
        props.changeStatus(props.bid, 0, props.name);
       
      })
      .catch((err) => {
        //console.log(err);
        store.addNotification({
          title: Error,
          message: "Could not Complete Action. Please try Again",
          type: "danger",
          insert: "top",
          dismiss: {
            duration: 3000,
            pauseOnHover: true,
            onScreen: true,
          },
          width: 2000,
          container: "top-center",
          animationIn: ["animate__animated", "animate__bounceInDown"],
          animationOut: ["animate__animated", "animate__fadeOut"],
        });
        setLoading(false);
      });
  };

  //for approving
  const plansModalUpdate = () => {
    props.changeStatus(props.bid, 1, props.name);
  };





  return (
    <>
     
      {loading ? (
        <tr className="">
          <td></td> <td></td> <td></td>
          <td>
            <SpinnerLogin />
          </td>
          <td></td> <td></td> <td></td>
        </tr>
      ) : (
        <tr
          className={props.status === 1 ? "cursor-pointer" : " cursor-pointer"}
        >
          <td
            className=""
            data-toggle="modal"
            data-target="#staticBackdrop"
            onClick={() => sendBid()}
          >
            {props.name ? props.name : "N/A"}
          </td>
          <td
            data-toggle="modal"
            data-target="#staticBackdrop"
            onClick={() => sendBid()}
          >
            {props.address}
          </td>
          <td
            data-toggle="modal"
            data-target="#staticBackdrop"
            onClick={() => sendBid()}
          >
            {props.owner_name ? props.owner_name : <span>N/A</span>}
          </td>
          <td
            data-toggle="modal"
            data-target="#staticBackdrop"
            onClick={() => sendBid()}
          >
            {props.owner_email}
          </td>
          <td
            data-toggle="modal"
            data-target="#staticBackdrop"
            onClick={() => sendBid()}
          >
            {props.owner_mobile ? props.owner_mobile : <span>N/A</span>}
          </td>
          <td>
            
            <Moment parse="YYYY-MM-DD" format="MMM DD YYYY">
              
              {props.date}
            </Moment>
          </td>
          <td>
            
            {props.status === 0 ? (
              <span
                className="badge badge-warning p-2 text-dark"
                style={{ fontSize: "" }}
              >
                Pending
              </span>
            ) : props.status === 1 ? (
              <span className="badge p-2 badge-success text-dark">
                Verified
              </span>
            ) : (
              <span className="badge p-2  badge-danger text-dark">Rejected</span>
            )}
          </td>

          <td className="text-right">
            <div className="dropdown">
              <span
                className="dropdown-ellipses dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  style={{ color: "darkgray" }}
                  className={" fe fe-more-vertical"}
                ></i>
              </span>

              <div className="dropdown-menu dropdown-menu-right">
                {props.status === 0 ? (
                  <div>
                    <span
                      className="dropdown-item btn"
                      data-toggle="modal"
                      data-target="#modal2"
                      onClick={plansModal}
                    >
                      Approve
                    </span>

                    <span className="dropdown-item btn"  data-toggle="modal"  onClick={()=>setModal3(true)} data-target="#logoutModal" >
                      Reject
                    </span>
                  </div>
                ) : props.status === 1 ? (
                  <span className="dropdown-item btn" onClick={()=>setModal3(true)} data-toggle="modal" data-target="#logoutModal" >
                    Reject
                  </span>
                ) : (
                  <span
                    onClick={plansModal}
                    data-toggle="modal"
                    data-target="#modal2"
                    className="dropdown-item btn"
                  >
                    Approve
                  </span>
                )}
              </div>
            </div>
          </td>
        </tr>
      )}
                {modal3?
            <ConfirmationModal names={props.name} unload={setModal3} rejectfunc={reject}></ConfirmationModal>
                :null}
      {/* modal for the plans list */}
      {modal2 ? (
        <Plansmodal
          name={props.name}
          bid={props.bid}
          address={props.address}
          owner_name={props.owner_name}
          owner_email={props.owner_email}
          owner_mobile={props.owner_mobile}
          load={setModal2}
          success={plansModalUpdate}
        />
      ) : null}

      {/* modal for the restaurant details */}
      {modal ? (
        <Restaurantmodal
          bid={props.bid}
          name={props.name}
          address={props.address}
          owner_name={props.owner_name}
          owner_email={props.owner_email}
          owner_mobile={props.owner_mobile}
          load={setModal}
          dishTypes={props.cuisineTypes}
        ></Restaurantmodal>
      ) : null}
    </>
  );
}
