import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import Sidebar from "./Sidebar";
//import piza from "../assets/img/40-offf.png";
import { withRouter } from "react-router-dom";
import Spinners from "../assets/Spinners/Spinners";
import { getAPI } from "../Utils/Api-Interface";
//import Pagination from '../assets/PaginationComponent/Pagination';
import ReactPaginate from "react-paginate";
import Table from "../Components/Table";
//import { store } from "react-notifications-component";
import notif from "../Components/Notifications/notifService";

class Restaurants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      token: null,
      totalPages: 0,
      currentPage: 1,
      loadPagination: false,
      search: "",
      searched: false,
      loading2: false,
    };
    this.textInput = React.createRef();
  }

  // on mount API gets hit to get the restaurants list.
  componentDidMount() {
    this.getRestaurantInfo();
  }

  // fetches the list of the restaurants.
  getRestaurantInfo = () => {
    this.setState({ loading2: true });

    if (this.state.searched) {
      this.setState({ searched: false });
    }

    getAPI(
      `/admin/getAllRestaurants?page=${this.state.currentPage}&limit=10&name=`,
      localStorage.getItem("auth")
    )
      .then((res) => {
        // setting on the basis of the status code of the api response.

        if (res.data.data.length > 0) {
          console.log(res.data);
          this.setState({ data: res.data }, () => {
            this.setState({ totalPages: res.data.totalPages });
            this.setState({ currentPage: parseInt(res.data.currentPage) });
            this.setState({ loading2: false });
          });
        }
      })

      .catch((err) => {
        //console.log(err.response);
        this.setState({ data: null }, () => {
          this.setState({ loading2: false });
          if (err.response.status == 401) {
            notif(
              `${err.response.data.message.toUpperCase()}`,
              " Please logout and try again ",
              "danger"
            );
          } else {
            notif(`OOPS`, " Please try again after some time ", "danger");
          }
        });
      });
  };

  searchRestaurant = () => {
    this.setState({ searched: true });
    this.setState({ loading2: true });

    getAPI(
      `/admin/getAllRestaurants?page=1&limit=100&name=${this.state.search}`,
      localStorage.getItem("auth")
    )
      .then((res) => {
        // console.log(res);
        if (res.data.data.length > 0) {
          //console.log("============SEARCH RESULTS RECIEVED============");
          // console.log(res.data);
          this.setState({ data: res.data }, () => {
            // this.setState({ totalPages: res.data.totalPages });
            // this.setState({ currentPage: parseInt(res.data.currentPage) });
          });

          this.setState({ loading2: false });
        } else {
          this.setState({ data: null }, () => {
            this.setState({ loading2: false });
          });
        }
        this.textInput.current.focus();
      })

      .catch(() => {
        this.setState({ data: null }, () => {
          this.setState({ loading2: false });
        });
      });
  };

  //clearnig the restaturant state data when the component umnounts.
  componentWillUnmount() {
    // console.log("restaurant is unmounting");
    this.setState({ data: null });
  }

  //works when the pagination chnages.
  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      // console.log('page changed',this.state.currentPage)
      this.getRestaurantInfo();
    }
  }

  // handles the pagination and fetches new data
  handlePageClick = (data) => {
    this.setState({ currentPage: data.selected + 1 });
  };

  // changes the status from reject to approve and vice versa.
  changeStatus = (bid, method, name) => {
    const pivot = this.state.data.data.findIndex((info) => {
      return bid === info.id;
    });

    //reject method
    if (pivot >= 0) {
      const tempArray = { ...this.state.data };
      if (method === 0) {
        tempArray.data[pivot].status = 2;
        // setting the states
        this.setState({ loading2: true });
        this.setState({ data: tempArray }, () => {
          this.setState({ loading2: false });
        });

        notif(name, "has been marked rejected", "danger");
      } else {
        //for aceepting.
        tempArray.data[pivot].status = 1;

        // setting the states
        this.setState({ loading2: true });
        this.setState({ data: tempArray }, () => {
          this.setState({ loading2: false });

          notif(name, "has been approved", "success");
        });
      }
    }
  };

  searchHandle = (e) => {
    if (e.target.value.length >= 1) {
      if (e.target.value.length === 1) {
        this.setState({ search: e.target.value.toUpperCase() }, () => {
          this.searchRestaurant();
        });
      } else
        this.setState({ search: e.target.value }, () => {
          this.searchRestaurant();
        });
    } else {
      this.setState({ search: e.target.value });

      this.getRestaurantInfo();
    }
  };

  search = (e) => {
    e.preventDefault();
    this.searchRestaurant();
  };

  render() {
    return (
      <section>
        <Sidebar />

        <div className="main-content">
          <div className="header">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row align-items-end">
                  <div className="col">
                    <h6 className="header-pretitle"> Overview </h6>
                    <h1 className="header-title">Restaurants</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="card">
              <div className="card-header align">
                <h4 className="card-header-title p-3"> Restaurants List </h4>
                <div className="d-flex align-items-center">
                  {this.state.searched ? (
                    <div>
                      <i
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Back Home"
                        onClick={() => {
                          this.setState({ search: "" }, () =>
                            this.getRestaurantInfo()
                          );
                        }}
                        className="fa fa-arrow-left mr-4 cursor-pointer"
                        style={{ fontSize: "20px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  ) : null}

                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        value={this.state.search}
                        className="form-control search-input"
                        onChange={this.searchHandle}
                        placeholder="Search Restaurants"
                        style={{
                          border: "2px solid #F7A901",
                          borderRadius: "%",
                        }}
                        ref={this.textInput}
                      />
                    </div>
                  </form>
                </div>
              </div>
              {this.state.loading2 ? (
                <Spinners />
              ) : (
                <div className="table-responsive mb-0">
                  <table className="table table-sm  card-table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Restaurant Name</th>
                        <th scope="col">Location</th>
                        <th scope="col">Account Owner </th>
                        <th scope="col">Owner's Email </th>
                        <th scope="col">Owner's Ph </th>
                        <th colSpan="col">Created Date</th>
                        <th scope="col"> Status</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.data ? (
                        this.state.data.data.map((info, index) => {
                          return (
                            <Table
                              bid={info.id}
                              key={index}
                              name={info.name}
                              address={info.address}
                              date={info.createdAt}
                              owner_name={info.owner_name}
                              owner_email={info.owner_email}
                              owner_mobile={info.owner_mobile}
                              status={info.status}
                              changeStatus={this.changeStatus}
                              cuisineTypes={info.cuisine_type}
                            />
                          );
                        })
                      ) : (
                        <tr className="mt-3 text-center">
                          <td>No records found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            {!this.state.searched ? (
              <nav aria-label="Page navigation example">
                {!(!this.state.loading && this.state.loadPagination) && (
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    disableInitialCallback={true}
                    forcePage={
                      this.state.dataError && this.state.currentPage - 1
                    }
                    pageCount={this.state.totalPages}
                    initialPage={this.state.currentPage - 1}
                    activeLinkClassName={"page-link active"}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    containerClassName="pagination pagination-lg justify-content-center"
                    onPageChange={this.handlePageClick}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    disabledClassName={"disabled"}
                    activeClassName="page-item active"
                  ></ReactPaginate>
                )}
              </nav>
            ) : null}
          </div>
        </div>
        <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-4">
          <div className="container-fluid">
            <div className="d-block text-white text-center mb-0 opacity-8 font-sm">
              {/* <span class="mr-2"><img class="navbar-brand-img mx-auto" src={require('..assets/img/footerlogo.png')}alt="..." /></span> */}
              <span className="position-relative top-4">
                Copyright © 2020 Roarflash. All Rights Reserved.
              </span>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Restaurants);
