import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { postLoginAPI } from "../../Utils/Api-Interface";
import { Animated } from "react-animated-css";
import SpinnerLogin from "../../assets/Spinners/SpinnerLogin";
//import * as Yup from 'yup';
import $ from "jquery";
import notif from "../../Components/Notifications/notifService";

//  import {useHistory} from 'react-router-dom';

export default function Plansconfirmation(props) {
  const [loading, setLoading] = useState(false);

  // const validationSchema=Yup.object().shape({
  // optionalObject:Yup.lazy(value=>{
  //           if(value=='limit'){
  //             return Yup.object.shape({
  //               planName:Yup.string().required('Required Field'),
  //               price:Yup.number().integer().required('Required'),
  //               days:Yup.number().integer().required('Required Field'),
  //               flashes:Yup.number().integer().required('Number Required')

  //             })
  //           }else{
  //           return Yup.object().shape({
  //             planName:Yup.string().required('Required Field'),
  //               price:Yup.number().integer().required('Required'),
  //               days:Yup.number().integer().required('Required Field')
  //           })
  //         }
  // })
  // })

  function isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  const formik = useFormik({
    initialValues: {
      planName: "",
      price: 0,
      days: 0,
      flashes: 0,
      limit: "limit",
    },

    onSubmit: (values) => {
      let value;

      //checks if the value of the Field is an integer or an,
      if (isInt(values.price) == true) {
        let len = `${values.price}`.length + 2;
        value = values.price.toPrecision(len);
      } else {
        value = values.price;
      }

      let data;
      //checks weather the no of days is limited or limited.
      if (values.limit !== "limit") {
        data = {
          plan_name: values.planName,
          no_of_flashes: "unlimited",
          no_of_days: 0,
          prices: [
            {
              price: value,
              currency: "usd",
            },
          ],
        };
      } else {
        data = {
          plan_name: values.planName,
          no_of_flashes: values.flashes,
          no_of_days: 0,
          prices: [
            {
              price: value,
              currency: "usd",
            },
          ],
        };
      }

      console.log(data);
      setLoading(true);
      postLoginAPI(`/admin/savePlan`, data, localStorage.getItem("auth"))
        .then((res) => {
          console.log(res);
          notif(values.planName, "was created sucessfully", "success");
          setLoading(false);
          closeModal();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            notif(
              `${err.response.data.message.toUpperCase()}`,
              " Please logout and try again ",
              "danger"
            );
          } else {
            notif(`OOPS`, " Please try again after some time ", "danger");
          }
          setLoading(false);
        });
    },

    validate: (values) => {
      let errors = {};
      if (!values.planName) {
        errors.planName = "Required";
      }

      if (!values.price) {
        errors.price = "Required";
      } else if (values.price < 0) {
        errors.price = "Invalid Price ";
      }

      if (values.limit === "limit") {
        if (!values.flashes) {
          errors.flashes = "Required";
        } else if (values.flashes <= 0) {
          errors.flashes = "Invalid Flashes";
        }
      }

      return errors;
    },
  });

  //  useEffect(()=>{
  //   if(formik.values.limit=='limit'){
  //       setShow(true)
  //   }else{
  //     setShow(false)
  //   }

  //  },[formik.values.limit])
  //   console.log(formik.errors)

  function closeModal() {
    window.$("#confirm").modal("hide");
    $(".modal-backdrop").remove();
    window.$("#confirm").on("hidden.bs.modal", function (e) {
      // do something...
      console.log("unmounting");
      props.fetch();
      props.mount(false);
    });
  }

  return (
    <div
      className="modal fade"
      data-keyboard="false"
      data-backdrop="static"
      id="confirm"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg "
        role="document"
      >
        <div className="modal-content text-center">
          <div className="modal-body">
            <div className="modal-header">
              <h2 className="modal-title text-left"> Create a Plan</h2>
            </div>
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className="row text-left mt-4">
                <div className="col form-group">
                  <label>Plan Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Plan Name"
                    name="planName"
                    value={formik.values.planName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus
                  />
                  {formik.touched.planName && formik.errors.planName ? (
                    <Animated animationIn="fadeIn" animationOutDuration={200}>
                      {" "}
                      <span
                        className=" "
                        style={{
                          color: "red",
                          marginTop: "",
                          marginBottom: "-1.5rem",
                          textAlign: "",
                        }}
                      >
                        {" "}
                        {formik.errors.planName}
                      </span>{" "}
                    </Animated>
                  ) : null}
                </div>
                <div className="col form-group">
                  <label>Price</label>

                  <div className="input-group-prepend">
                    <div className="input-group-text">$</div>
                    <input
                      type="number"
                      className="form-control"
                      placeholder={formik.values.price}
                      name="price"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.price && formik.errors.price ? (
                    <Animated animationIn="fadeIn" animationOutDuration={200}>
                      {" "}
                      <span
                        className=" "
                        style={{
                          color: "red",
                          marginTop: "",
                          marginBottom: "-1.5rem",
                          textAlign: "",
                        }}
                      >
                        {" "}
                        {formik.errors.price}
                      </span>{" "}
                    </Animated>
                  ) : null}
                </div>
              </div>
              <div className="row mt-4 text-left">
                {/* <div className="col form-group">
                  <label className="text-left">No of Days</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="No Of Days"
                    name="days"
                    value={formik.values.days}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.days && formik.errors.days ? (
                    <Animated animationIn="fadeIn" animationOutDuration={200}>
                      {" "}
                      <span
                        className=" "
                        style={{
                          color: "red",
                          marginTop: "",
                          marginBottom: "-1.5rem",
                          textAlign: "",
                        }}
                      >
                        {" "}
                        {formik.errors.days}
                      </span>{" "}
                    </Animated>
                  ) : null}
                </div> */}

                <div className="col  form-group">
                  <label> Flashes Limit </label>
                  <select
                    name="limit"
                    value={formik.values.limit}
                    class="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="limit">Limit</option>
                    <option value="unlimited"> Unlimited</option>
                  </select>
                </div>
              </div>

              <Animated
                animateOnMount={false}
                isVisible={formik.values.limit === "limit" ? true : false}
                animationIn="fadeIn"
              >
                <div className="row d-flex align-items-center  text-left">
                  <div class="col form-group">
                    <label>Set total flashes</label>
                    <input
                      type="number"
                      onKeyDown={(event) => {
                        if (
                          event.keyCode === 38 ||
                          event.keyCode === 40 ||
                          event.keyCode === 69 ||
                          event.keyCode === 190 ||
                          event.keyCode === 189 ||
                          event.keyCode === 109 ||
                          event.keyCode === 110
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control w-50"
                      placeholder={formik.values.flashes}
                      name="flashes"
                      value={formik.values.flashes}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.flashes && formik.errors.flashes ? (
                      <Animated animationIn="fadeIn" animationOutDuration={200}>
                        {" "}
                        <span
                          className=" "
                          style={{
                            color: "red",
                            marginTop: "",
                            marginBottom: "-1.5rem",
                            textAlign: "",
                          }}
                        >
                          {" "}
                          {formik.errors.flashes}
                        </span>{" "}
                      </Animated>
                    ) : null}
                  </div>
                </div>
              </Animated>

              <div className="modal-footer2">
                <button type="btn" className="btn btn-primary w-25 mr-3">
                  {loading ? <SpinnerLogin /> : "Confirm"}
                </button>

                <button
                  type="button"
                  className="btn btn-danger w-25 ml-3"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
