import React from 'react'
import $ from 'jquery';
import {useEffect} from 'react';
import SpinnerLogin from '../../assets/Spinners/SpinnerLogin';
import {useState} from 'react';
import { disablePlan } from "../../Utils/Api-Interface";
import notif from '../Notifications/notifService';
export default function Disable(props) {

  //sets the UI spin loader to mount and unmount.
  const [loading, setLoading] = useState(false)

   

    
  function disable(){
    console.log(props.id)
    setLoading(true)
    disablePlan(`/admin/disable_plan?planId=${props.id}&status=0`,localStorage.getItem('auth'))
    .then((res)=>{
    console.log(res)
      notif(props.name,res.data.message,'success',1500)
      setLoading(false)
      closeModal()
    })

    .catch((err)=>{

       setLoading(false)
      if(err.response.status==401){
        notif(`${err.response.data.message.toUpperCase()}`, " Please logout and try again ", "danger");
       }else{
        notif(`OOPS`, " Please try again after some time ", "danger");
       }
    })
  }


  // closes the modal with the help of jQuery.
   function closeModal(){
  window.$('#disable').modal('hide')
  $('.modal-backdrop').remove()
  
  // this insance runs when the modal hides from the view.
  window.$("#disable").on('hidden.bs.modal',function(e){
     console.log('unmoutning the disable service')
     props.fetch()  
    props.mount(false)
  
  })
 
   }

    return (
        <div
        className="modal fade"
        data-keyboard="false"
        data-backdrop="static"
        id="disable"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content text-center">
            {/* <div className="modal-header text-center">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div> */}
            <div className="modal-body">
    

           <h3> {props.name} will be disabled </h3>

            </div>
            <div className="modal-footer">
            <button type="button" onClick={disable}  className="btn btn-primary w-25">
               {loading?<SpinnerLogin/>:'Yes,Confirm'}
              </button>

              <button
                type="button"
                className="btn btn-danger w-25"
                data-dismiss="modal"
              >
                No,Cancel
              </button>
             
            </div>
          </div>
        </div>
      </div>
    )
}
