import React from 'react';
import  './assets/css/theme.css';
import  './assets/css/custom.css';
import './assets/font/feather/feather.css';
import Routes from './Routing/Routes';



function App() {
 
  return (
    
    <Routes/>
   
  );
}

export default App;
