import React from "react";
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import { getAPI } from "../../../Utils/Api-Interface";
import SpinnerLogin from "../../../assets/Spinners/SpinnerLogin";
import Moment from 'react-moment';
import moment from 'moment-timezone'


export default function Roarflashsales(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  //runs on mounting and sets the data.
   useEffect(() => {
     
    setData(props.salesData);
  }, [props.salesData]);


  const pageChange = (e) => {
    const token = localStorage.getItem("auth");
    let page = e.selected + 1;
    //console.log(e.selected + 1, " at this page");
    if(error){
      setError(false)
    }

      setLoading(true);
      getAPI(
        `/admin/restaurants_details?page=${page}&limit=5&businessId=${props.bid}`,
        token
      )
        .then((res) => {
          //console.log(res.data);
          setData(res.data.data);
          setError(false);
          setLoading(false);
         
        })
        .catch((err) => {
         // console.log(err);
          setError(true);
          setLoading(false);
        });
   
  };

  return (
    <div
      className="tab-pane fade"
      id="Winner"
      role="tabpanel"
      aria-labelledby="Winner-tab"
    >
      <div className="card shadow-none">
        <div className="table-responsive mb-0">
          <table className="table table-sm card-table">
            <thead>
              <tr>
                <th scope="col">Name </th>
                <th scope="col">Date (MM/DD/YY) </th>
                <th scope="col">Time</th>
                <th scope="col">Price($) </th>
                <th scope="col">Discount(%) </th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <SpinnerLogin />
                  </td>
                </tr>
              ) : error ? 
                <tr>
                  <td>Could not fetch Records. </td>
                </tr>
               : data.length > 0 ? (
                data.map((sale, index) => (
                  <tr key={index}>
                    <td>
                      <div className="avatar avatar-xs d-inline-block mr-2">
                        <img
                          src={ `https://roar-app-assets.s3-us-west-2.amazonaws.com/${sale.sale_image}`}
                          alt="pic"
                          className="avatar-img rounded"
                        />
                      </div>
                      <span>{sale.name}</span>
                    </td>
                    <td> <Moment parse="YYYY-MM-DD" format="MMM DD YYYY"> {sale.date} </Moment></td>
                    <td>
                       { moment.utc(sale.start_time).local().format('hh:mm a')}
                      <span>-</span>
                      { moment.utc(sale.end_time).local().format('hh:mm a')}
                    </td>
                    <td> {sale.fixed_price}</td>
                    <td>{sale.discount_percent}</td>
                    <td>
                      {sale.sale_status===2?<span className="badge badge-danger text-dark"> Expired</span>:sale.sale_status===1?<span className="badge badge-success text-dark">Active </span>:<span className="badge badge-warning text-dark">Draft</span>}
                     
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No records Here.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <nav aria-label="Page navigation example">
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          disableInitialCallback={true}
          pageCount={props.totalPages}
          initialPage={0}
          activeLinkClassName={"page-link active"}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          containerClassName="pagination pagination-lg justify-content-center"
          onPageChange={pageChange}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          disabledClassName={"disabled"}
          activeClassName="page-item active"
        ></ReactPaginate>
      </nav>
    </div>
  );
}
