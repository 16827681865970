import React from 'react';

export default function Spinners(){

    return(
       
            <span className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">Loading...</span>
            </span>
    

    )
}
