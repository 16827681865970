import React from "react";
import {} from "react-router-dom";
import Sidebar from "./Sidebar";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { Animated } from "react-animated-css";
import { postLoginAPI } from "../Utils/Api-Interface";
import SpinnerLogin from "../assets/Spinners/SpinnerLogin";
import notif from "../Components/Notifications/notifService";
//import {Redirect} from 'react-router-dom';

export default function Setting() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  // use effect for the token
  // this side effect checks fo the token.

  const toggle = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  // yup validation here
  const validationSchema = Yup.object({
    currentPass: Yup.string().required("Required Field"),
    newPass: Yup.string()
      .min(8, "Minimum 8 Characters Needed")
      .matches(
        /^.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?].*$/,
        "Need at least one special character"
      )
      .matches(/^(?=.{0,100}$)\D*\d/, "Need at least a number")
      .required("Required Field"),
    confirmPass: Yup.string()
      .oneOf([Yup.ref("newPass")], "Mismatched Passwords")
      .required("Required Field"),
  });

  const formik = useFormik({
    initialValues: {
      currentPass: "",
      newPass: "",
      confirmPass: "",
    },
    onSubmit: (values) => {
      const authtoken = localStorage.getItem("auth");

      let currentData = {
        current_pass: values.currentPass,
        confirm_pass: values.confirmPass,
      };

      setLoading(true);
      postLoginAPI(`/admin/change_password`, currentData, authtoken)
        .then((res) => {
          setLoading(false);
          console.log(res);
          if (res.data.status < 1) {
            notif("Could not update Password", res.data.message, "danger");
          } else {
            notif("Password has been Updated", res.data.message, "success");
          }
        })
        .catch((err) => {
          //console.log(err)
          notif("Oops", err.message, "danger");
          setLoading(false);
        });
    },
    validationSchema,
  });

  return (
    <section>
      <Sidebar />
      <div className="main-content">
        <div className="header">
          <div className="container-fluid">
            <div className="header-body">
              <div className="row align-items-end">
                <div className="col">
                  <h6 className="header-pretitle"> Overview </h6>
                  <h1 className="header-title">Settings</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Animated animationIn="fadeIn" animationInDuration={400}>
          <div className="container">
            <div className="row justify-content-between align-items-center mb-5">
              <div className="col-12 col-md-12 col-xl-12">
                <h2 className="mb-2"> Change your password </h2>
                <p className="text-muted mb-xl-0">
                  Changing password will immediately change you password. You
                  will need to login with the new password in your next sign-in
                  to the dashboard.
                </p>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-6 order-md-2">
                <div className="card bg-light border ml-md-4 mt-5">
                  <div className="card-body">
                    <p className="mb-2"> Password requirements </p>
                    <p className="small text-muted mb-2">
                      To create a new password, you have to meet all of the
                      following requirements:
                    </p>
                    <ul className="small  pl-4 mb-0">
                      <li className=""> Minimum 8 character </li>
                      <li> At least one special character </li>
                      <li> At least one number </li>
                      <li> Can’t be the same as a previous password </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <label> Current password </label>
                    <input
                      style={{ marginBottom: "" }}
                      id="currentPass"
                      name="currentPass"
                      autoFocus
                      value={formik.values.currentPass}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="password"
                      className="form-control"
                    />
                    {formik.touched.currentPass && formik.errors.currentPass ? (
                      <Animated animationIn="fadeIn" animationinDuration={300}>
                        <span
                          className=" "
                          style={{
                            color: "red",
                            marginTop: "",
                            marginBottom: "-1.5rem",
                            textAlign: "",
                          }}
                        >
                          {" "}
                          {formik.errors.currentPass}
                        </span>{" "}
                      </Animated>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label> New password </label>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type={show ? "text" : "password"}
                        style={{ marginRight: "-1.5rem", marginBottom: "" }}
                        name="newPass"
                        id="newPass"
                        value={formik.values.newPass}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        className="form-control"
                      />
                      <i
                        className={show ? "fa fa-eye" : "fa fa-eye-slash"}
                        aria-hidden="true"
                        style={{ zIndex: "1" }}
                        onClick={toggle}
                      ></i>
                    </div>
                    {formik.touched.newPass && formik.errors.newPass ? (
                      <div style={{ color: "red", marginBottom: "" }}>
                        {" "}
                        {formik.errors.newPass}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label> Confirm new password</label>
                    <input
                      name="confirmPass"
                      value={formik.values.confirmPass}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="password"
                      className="form-control"
                    />
                    {formik.touched.confirmPass && formik.errors.confirmPass ? (
                      <div style={{ color: "red", marginBottom: "" }}>
                        {" "}
                        {formik.errors.confirmPass}
                      </div>
                    ) : null}
                  </div>

                  <button
                    disabled={!(formik.dirty && formik.isValid) || loading}
                    className="btn btn-block btn-primary"
                    type="submit"
                  >
                    {loading ? <SpinnerLogin /> : "Update Password"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Animated>
        <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-4">
          <div className="container-fluid">
            <div className="d-block text-white text-center mb-0 opacity-8 font-sm">
              {/* <span class="mr-2"><img class="navbar-brand-img mx-auto" src="" alt="..." /></span> */}
              <span className="position-relative top-4">
                Copyright © 2020 Roarflash. All Rights Reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
