import axios from "axios";
import { Baseurl } from "./BaseUrl";

export const postLoginAPI = (path, data,token) => {
  return axios({
    method: "post",
    url: Baseurl + path,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer "+ token
    },
    data:JSON.stringify(data)
  });
};

// export const updatePasswordAPI=(path,data,token)=>{
//   return axios({
//     method:'post',
//     url:Baseurl+ path,
//     headers:{
//       "content-type": "application/json",
//       Authorization: "Bearer "+ token
//     },
//     data:JSON.stringify(data)
//   })
// }

export const disablePlan=(path,token)=>{
  return axios({
    method:'delete',
    url:Baseurl+path,
    headers:{
      "content-type":"application/json",
      Authorization:"Bearer "+ token

    }

  })
}





// for the fetch queries,

export const getAPI=(path,token,source)=>{
  
  return axios({
    method:'get',
    url:Baseurl+path,
    headers:{
      "content-type": "application/json",
      Authorization: "Bearer " + token
    }
  })
}



// // this is the fetch url.
// export const try1=(data)=>{
// return fetch('http://54.184.167.65:4100/admin/admin_login',{
//     method:'POST',
//     headers:{
//         "Content-Type":"application/json",
//         Authorization:"Bearer",
         
//     },
//     data:data
// })



// }