import React from "react";
import { getAPI } from "../Utils/Api-Interface";
import Sidebar from "./Sidebar";
import ReactPaginate from "react-paginate";
import Spinners from "../assets/Spinners/Spinners";
import { useState, useEffect,useRef } from "react";
import axios from "axios";
import GeneralTable from "../Components/Table/GeneralTable";

export default function Suggested() {
  const [loading, setLoading] = useState(false);
  const [loadPagination, setLoadPagination] = useState(false);

  const searchInput=useRef()

  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages:null,
  });

  const [data, setData] = useState([]);
  const [search,setSearch]=useState('')

  useEffect(() => {
    setLoading(true)
    fetchSuggested(pageInfo.currentPage,'');
  }, [])




  const fetchSuggested = (page,searchtext) => {

  !loading&&setLoading(true)


    axios.get(`https://api.roarflash.com/admin/getSuggestedRestaurants?page=${page}&limit=10&name=${searchtext?searchtext:''}`).then((res) => {
        console.log(res.data);

        setPageInfo({
          currentPage: res.data.currentPage,
          totalPages: res.data.totalPages,
        });

        setData(res.data.data);
        
      }).catch((err) => {
        console.log(err);
      }).then((resullt)=>{
        setLoading(false)
      })
  }


   let searchHandle=(e)=>{
      console.log(e.target.value)
      if(e.target.value.length>0) fetchSuggested(1,e.target.value)
      else fetchSuggested(1)

   }


  const handlePageClick = (data) => {
    console.log(data.selected+1)
     fetchSuggested(data.selected+1)
   
  }

  return (
    <section>
      <Sidebar />

      <div className="main-content">
        <div className="header">
          <div className="container-fluid">
            <div className="header-body">
              <div className="row align-items-end">
                <div className="col">
                <h6 className="header-pretitle"> Overview </h6>
                  <h1 className="header-title"> Suggested Restaurants</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="card">
            <div className="card-header align">
            <h4 className="card-header-title p-3"> Restaurants List </h4>
              <div className="d-flex align-items-center">
                <form>
                <div className="input-group">
                      <input
                        type="text"
                        
                        className="form-control search-input"
                        onChange={searchHandle}
                        placeholder="Search Restaurants"
                        style={{
                          border: "2px solid #F7A901",
                          borderRadius: "%",
                        }}
                       ref={searchInput}
                      />
                     
                    </div>

                </form>
              </div>
            </div>
            {loading ? (
              <Spinners />
            ) : (
              <div className="table-responsive mb-0">
                <table className="table table-sm  card-table  table-hover">
                  <thead>
                    <tr>
                      <th scope="col"> User Phone </th>
                      <th scope="col"> Email</th>
                      <th scope="col"> Restaurant Name</th>
                      <th scope="col"> Address </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0
                      ? data.map((application, index) => {
                          return (
                            <GeneralTable
                              key={application.id}
                              user_mobile={application.user_mobile}
                              user_email={application.user_email}
                              name={application.name}
                              address={application.address}
                            />
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
            )}
          </div>
         
          <nav aria-label="Page navigation example">
            {!(!loading && loadPagination) && 
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                disableInitialCallback={true}
                pageCount={pageInfo.totalPages}
                initialPage={pageInfo.currentPage-1}
                activeLinkClassName={"page-link active"}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                containerClassName="pagination pagination-lg justify-content-center"
                onPageChange={handlePageClick}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                disabledClassName={"disabled"}
                activeClassName="page-item active"
              ></ReactPaginate>
            }
          </nav>
        </div>
      </div>
      <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-4">
        <div className="container-fluid">
          <div className="d-block text-white text-center mb-0 opacity-8 font-sm">
            {/* <span class="mr-2"><img class="navbar-brand-img mx-auto" src={require('..assets/img/footerlogo.png')}alt="..." /></span> */}
            <span className="position-relative top-4">
              Copyright © 2020 Roarflash. All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
