import React from 'react';
import {useFormik} from 'formik';
import {useState} from 'react';
// import {useRef} from 'react';
import { postLoginAPI} from '../Utils/Api-Interface';
import {useHistory} from 'react-router-dom';
import SpinnerLogin from '../assets/Spinners/SpinnerLogin';
//import * as Yup from 'yup';

import {Animated} from "react-animated-css";

export default function Logout() {

   const[error,setError]=useState(false)
   const [errorMsg,setErrorMsg]=useState(' ');
    const[loading,setloading]=useState(false)
    const [show,setShow]=useState(false);
    const history = useHistory();



     
     // a library that handles from submission and much more.     
      const formik=useFormik({
          initialValues:{
              email:'',
              password:''
          },
          onSubmit:(values)=>{

           

              const data={email:values.email,password:values.password}
             

             setloading(true)

             
            // api hit for login
            postLoginAPI(`/admin/admin_login`, data).then((res)=>{
                setloading(false)
             
                if(res.data.authToken){
                   
            
                 const auth=res.data.authToken;
                 localStorage.setItem('auth',auth);
                 history.push("/restaurants")
                    
                }else{
                   
                    setError(true)
                    setErrorMsg(res.data.message);
                     toggle2()
                }



            }).catch((err)=>{
                //incase there is an server error or an error in our API
              //  console.log("login api err --->",err)
                setError(true)
                setErrorMsg('There seems to be some technical error')
                setloading(false)
                 toggle2()
            })
                                

          },

        //   validationSchema
          validate:values=>{
             let errors={};
             if(!values.email){
                 errors.email='Required Field'
             }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
                 errors.email='Invalid Email.'
             }         

             if(!values.password){
                 errors.password='Required Field'
             }

             return errors;
          }
      })


       //toggle function for the password.
      function toggle(){
          if(show){
              setShow(false)
          }else{
              setShow(true)
          }
      }

        //toggle animation
      function toggle2(){
        setTimeout(()=>{
            setError(false)
          },2000)
      }
            
         

        return (
            
            
                <div className="main-section">
                    <div className="container transform">
                        <div className="row justify-content-center">
                        

                            <div className="col-12 col-md-5 col-xl-4 my-5">
                                {/* {error?   <div class="alert alert-danger text-center fade show " role="alert">
                                  <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={500}> {errorMsg}</Animated>
                                    
                                 </div>:''}   */}
                                   
                                   
                                    <Animated animateOnMount={false} animationIn="fadeIn" animationOut="fadeOut" animationInDuration={400} isVisible={error} animationOutDelay={400} animationOutDuration={600}>       
                                    <div className="alert alert-danger text-center " role="alert">
                                      {errorMsg}
                                       </div>
                                      </Animated>  
                                      
                                      
                                      
                                         
                         
                            <Animated animationIn="fadeIn" animationinDuration={500} isVisible={true} animationOut="fadeOut" animationOutDuration={500} >
                                <h1 className="display-4 text-center mb-3">
                                    Sign in
                                 </h1>
                                <p className="text-muted text-center mb-5">
                                Access Roarflash service management dashboard.
                                </p>
                               
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="form-group">

                                        <label>Email Address</label>

                                        <input type="email" id='email' className="form-control" placeholder="Enter your email" name="email" onChange={formik.handleChange} value={formik.values.email} onBlur={formik.handleBlur} autoFocus/>
                                        <Animated animateOnMount={false} animationIn="fadeIn"  isVisible={ (formik.touched.email&&formik.errors.email)?true:false}  animationinDuration={300}>
                                        <span style={{color:'red',padding:'',textAlign:''}}> {formik.errors.email}</span>
                                        </Animated>
                                    </div>
                                                      
                                    
                                    
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col">

                                                <label>Password</label>
                                                

                                            </div>
                                            
                                        </div> {/* / .row */}
                                        

                                        <div className="input-group input-group-merge" style={{display:'flex',alignItems:'center'}}>

                                            <input type={show?"text":"password"} id='password' className="form-control" placeholder="Enter your password"  name='password' onChange={formik.handleChange} value={formik.values.password} onBlur={formik.handleBlur} style={{marginRight:'-1.4rem'}}/>
                                            <i className={show?"fa fa-eye":"fa fa-eye-slash"} aria-hidden="true" style={{"zIndex":'3',fontSize:'1.1rem'}} onClick={toggle}></i>
                                                   
                                        </div>
                                        <Animated animateOnMount={false} animationIn="fadeIn" isVisible={ (formik.touched.password&&formik.errors.password)?true:false} animationinDuration={300}>
                                        <span style={{color:'red',padding:'',textAlign:''}}> {formik.errors.password}</span>
                                        </Animated>
                                    </div>
                                   
                                   

                                    <button className="btn btn-lg btn-block btn-primary mb-3 mt-3" type='submit' disabled={!(formik.dirty&&formik.isValid)||loading}>
                                      {loading?<SpinnerLogin/>:"Login"}
                                    </button>
                                    
                                   
                                </form>
                                </Animated>
                            </div>
                        </div>
                    </div>
                </div>
             
        )
        
    
}