
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Toplogo from "../assets/img/logo.png";
import {withRouter} from 'react-router-dom';
 class Sidebar extends Component {
        
      
    logout=()=>{
       
       const status=window.confirm('Are you sure you want leave this page?')
        if(status){
            localStorage.removeItem('auth')
            this.props.history.push("/")
        }
        
        
    

}

    render() {
        return (
        
                <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark navbar-vibrant" id="sidebar">
                    <div className="container-fluid">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>

                        <div className="logo navbar-brand">
                             <img className="navbar-brand-img mx-auto" src={Toplogo} alt="..." /> 
                        </div>

                        <div className="collapse navbar-collapse" id="sidebarCollapse">

                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink to="/restaurants" className="nav-link"><svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="coffe-icon"><path d="M18 8h1a4 4 0 0 1 0 8h-1"></path><path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path><line x1="6" y1="1" x2="6" y2="4"></line><line x1="10" y1="1" x2="10" y2="4"></line><line x1="14" y1="1" x2="14" y2="4"></line></svg>Restaurants</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/suggestions" className="nav-link"> <i className="fa fa-cog mr-3" aria-hidden="true"/> Suggested Restaurants</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/manage" className="nav-link"> <i className="fa fa-cog mr-3" aria-hidden="true"/>Manage Plans</NavLink>
                                </li>
                               
                                <hr className="navbar-divider my-3 w-100"></hr>
                                {/* <li className="nav-item">
                                    <NavLink to="/manageaccount" className="nav-link"><i className="fe fe-edit" />Manage Accounts</NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink to="/setting" className="nav-link"><i className="fe fe-settings" />Settings</NavLink>
                                </li>
                                <li className="nav-item">
                                    <div className='nav-link' style={{cursor:'pointer'}} onClick={()=>this.logout()}>
                                    <i className="fe fe-unlock"   />Logout
                                    </div>
                                </li>
                                <hr className="navbar-divider my-3"></hr>
                            </ul>

                            <div className="mt-auto" />

                        </div>
                        <div className="navbar-user d-md-block d-none" id="sidebarUser">
                            <div className="d-block text-center navbar-brand pb-1">
                                
                                 <img src={Toplogo} alt="..." /> 
                              
                            
                            </div>
                        <p className="d-block text-white text-center mb-0 opacity-8 font-sm">Copyright © 2020 Roarflash<br/>All Rights Reserved.</p>
                        </div>
                    </div>
                </nav>
               

            
        )
    }
}

export default withRouter(Sidebar);