import React from "react";
import RestaurantDetails from "./RestaurantDetails/RestaurantDetails";
import Subscriptions from "./RestaurantDetails/Subscriptions";
import RoarflashSales from "./RestaurantDetails/RoarflashSales";
import { useEffect, useState } from "react";
import SpinnerLogin from "../../assets/Spinners/SpinnerLogin";
import { getAPI } from "../../Utils/Api-Interface";
import $ from "jquery";

export default function Restaurantmodal(props) {
  // all the states have been defined here.
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState([]);
  const [error, setError] = useState(false);
  const [pageCount, setPageCount] = useState(null);
  const [invoiceData, setInvoiceData] = useState([]);
  // const [isData, setIsData] = useState(true);

  useEffect(() => {
    // console.log(props.dishTypes)

    fetchRestaurantInformation();

    // cleanup function which clears the state.
    return () => {
      console.log("clearing the satte of modal");
      setModalData([]);
      setInvoiceData([]);
    };
  }, [props.bid]);

  const fetchRestaurantInformation = () => {
    const token = localStorage.getItem("auth");

    getAPI(
      `/admin/restaurants_details?page=1&limit=5&businessId=${props.bid}
  `,
      token
    )
      .then((res) => {
        console.log("===============Data from API 1recieved====== ");

        if (res.data.data.length > 0) {
          setModalData(res.data.data);
          setPageCount(res.data.totalPages);
          console.log(res.data.data[0]);
          console.warn("hitting the another api");

          getAPI(
            `/admin/getSubscriptions?businessId=${props.bid}&subscriptionId=${res.data.data?.[0]?.subscriptionId}`,
            token
          )
            .then((res) => {
              console.info("API 2 data recieved");
              setInvoiceData(res.data.data);
              setLoading(false);
            })

            .catch((err) => {
              console.error(err);
              setLoading(false);
            });
        } else {
          console.warn("========data is not present here=====");
          console.log(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  // this calls a setstate of the parent element which in turn fires the clean up function.
  const closeModal = () => {
    $(".modal-backdrop").remove();
    props.load(false);
    // $("#staticBackdrop").hide();
  };

  return (
    <div
      className="modal fade fixed-right"
      data-backdrop="static"
      data-keyboard="false"
      id="staticBackdrop"
    >
      <div className="modal-dialog  modal-dialog-vertical" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              className="close"
              data-dismiss="modal"
              style={{ fontSize: "2.0rem" }}
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="header">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-end">
                    <div className="col-12 p-0">
                      <h6 className="header-pretitle">Restaurant Details </h6>
                      {/* ======================name of the restaurant========================== */}
                      <h1 className="header-title">{props.name}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col">
                {!loading && (
                  <ul
                    className="nav  nav-tabs mb-4 nav-overflow"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <button
                        className="nav-link btn2 active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Information
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="nav-link btn2"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                      >
                        Subscriptions
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="nav-link btn2"
                        id="Winner-tab"
                        data-toggle="tab"
                        href="#Winner"
                        role="tab"
                        aria-controls="Winner"
                        aria-selected="false"
                      >
                        RoarFlash
                      </button>
                    </li>
                  </ul>
                )}

                <div className="tab-content" id="myTabContent">
                  {loading ? (
                    <div className="text-center">
                      <SpinnerLogin />
                    </div>
                  ) : error ? (
                    <h3>
                      Could not fetch Records Please try again after some time
                    </h3>
                  ) : (
                    <>
                      <RestaurantDetails
                        data={
                          modalData.length > 0
                            ? modalData[0].restaurant_information
                            : ""
                        }
                        dishTypes={props.dishTypes}
                        name={props.name}
                        address={props.address}
                        owner_name={props.owner_name}
                        owner_email={props.owner_email}
                        owner_mobile={props.owner_mobile}
                      />

                      <Subscriptions data={invoiceData} />

                      <RoarflashSales
                        bid={props.bid}
                        salesData={modalData}
                        totalPages={pageCount}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
