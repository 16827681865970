import React, { useState } from "react";
import { disablePlan } from "../../Utils/Api-Interface";
import SpinnerLogin from "../../assets/Spinners/SpinnerLogin";
import notif from "../../Components/Notifications/notifService";

export default function Planstable(props) {
  const [loading, setLoading] = useState(false);

  function enablePlan(id) {
    console.log(id);
    setLoading(true);
    disablePlan(
      `/admin/disable_plan?planId=${id}&status=1`,
      localStorage.getItem("auth")
    )
      .then((res) => {
        notif("Success", res.data.message, "success", 1500);
        setLoading(false);
        props.fetch()``;
      })

      .catch((err) => {
        setLoading(false);
        console.log(err);
      }, []);
  }

  return (
    <>
      {loading ? (
        <tr>
          <td></td>
          <td></td>
          <td className="text-center">
            {" "}
            <SpinnerLogin />
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      ) : (
        <tr>
          <td>{props.plan_name}</td>
          <td>{props.amount} </td>
          <td> {props.no_of_flashes} </td>
          <td>
            {props.isActive ? (
              <span className="badge p-1 badge-success text-dark">Active</span>
            ) : (
              <span className="badge p-1  badge-danger text-dark">
                Disabled
              </span>
            )}
          </td>
          <td className="d-flex justify-content-end">
            <div className="dropdown">
              <span
                className="dropdown-ellipses dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  style={{ color: "darkgray" }}
                  className={" fe fe-more-vertical"}
                ></i>
              </span>
              <div className="dropdown-menu dropdown-menu-right">
                <div>
                  {props.isActive ? (
                    <span
                      className="dropdown-item btn "
                      data-toggle="modal"
                      data-target="#disable"
                      onClick={() =>
                        props.disableModal(props.id, props.plan_name)
                      }
                    >
                      Disable
                    </span>
                  ) : (
                    <span
                      className="dropdown-item btn"
                      onClick={() => enablePlan(props.id)}
                    >
                      Enable
                    </span>
                  )}
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
