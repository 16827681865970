import React, { useEffect, useState } from "react";
import Sidebar from "../page/Sidebar";
import { getAPI } from "../Utils/Api-Interface";
import SpinnerLogin from "../assets/Spinners/SpinnerLogin";
import notif from "../Components/Notifications/notifService";
//import { useHistory } from "react-router-dom";
import Plansconfirmation from "../Components/ConfirmationModal/PlansConfirmation";
import $ from "jquery";
import Planstable from "../Components/Table/plansTable";

import Disable from "../Components/Modals/disable";
export function Manageplans(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal2, setModal2] = useState(false);
  const [modal, setModal] = useState(false);
  const [dataID, setDataId] = useState({
    id: "",
    name: "",
  });

  // on mount fetches the API and fetches the plans list
  useEffect(() => {
    fetch();
  }, []);

  // fetches again the API
  // useEffect(()=>{

  //    if(again===true){
  //      fetch()
  //    }
  //    console.log('fetch is running again')
  // },[again])

  //sets loading to false only when the data is present.
  useEffect(() => {
    if (data.length > 0) {
      setLoading(false);
    }
  }, [data]);

  // fecthes the plan list and sets the state to the data.
  function fetch() {
    setLoading(true);
    getAPI(`/admin/getAllPlans`, localStorage.getItem("auth"))
      .then((res) => {
        if (res.data.data.length > 0) {
          // console.info("data is there");
          setData(res.data.data);
          console.log(res.data.data);
        } else setLoading(false);
      })

      //catches if the error is false
      .catch((err) => {
        if (err.response.status == 401) {
          notif(
            ` ${err.response.data.message.toUpperCase()}`,
            "Please Logout and try Again",
            "danger"
          );
        } else {
          notif("OOPS", "Please try again after some time", " danger");
        }

        setLoading(false);
      });
  }

  const disablePlan = (id) => {
    //console.log(id)
    //  $('#disable').on('show.bs.modal', function (e) {
    //   var button = $(e.relatedTarget) // Button that triggered the modal
    //    console.log(button) // Extract info from data-* attributes
    //   // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    //   // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.

    // })
    const temp = data;
  };

  useEffect(() => {
    if (modal != true) {
      setDataId({ id: "", name: "" });
    }
  }, [modal]);

  const showModal = (id, name) => {
    setModal(true);
    setDataId({ id: id, name: name });
  };

  return (
    <section>
      <Sidebar />

      <div className="main-content">
        <div className="header">
          <div className="container-fluid">
            <div className="header-body">
              <div className="row align-items-end">
                <div className="col">
                  <h6 className="header-pretitle"> Overview </h6>
                  <h1 className="header-title"> Manage Plans </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title p-3"> Plans List </h4>
              <div className="">
                <i
                  class="fa fa-plus display-5 cursor-pointer"
                  data-toggle="modal"
                  data-target="#confirm"
                  aria-hidden="true"
                  onClick={() => setModal2(true)}
                ></i>
                {/* <button className="btn  btn-primary" data-toggle="modal" data-target="#confirm"> 
                       Add<i class="fa fa-plus pl-1 pr-1" aria-hidden="true"></i>  </button>    */}
              </div>
            </div>
            {loading ? (
              <div className="table-responsive mb-0">
                <table className="table table-hover table-sm">
                  <thead>
                    <tr>
                      {/* <th scope="col"> SELECT</th> */}
                      <th scope="col"> PLAN NAME </th>
                      <th scope="col"> PRICE ($)</th>
                      <th scope="col"> NO OF FLASHES </th>
                      <th colSpan=""> STATUS </th>
                      <th scope="col"> </th>
                    </tr>
                  </thead>
                </table>
                <div className="d-flex p-5 justify-content-center">
                  <SpinnerLogin />
                </div>
              </div>
            ) : (
              <div className="table-responsive mb-0">
                <table className="table table-hover table-sm">
                  <thead>
                    <tr>
                      {/* <th scope="col"> SELECT</th> */}
                      <th scope="col"> PLAN NAME </th>
                      <th scope="col"> PRICE ($)</th>
                      <th scope="col"> NO OF FLASHES </th>
                      <th colSpan=""> STATUS </th>
                      <th scope="col"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((info, index) => {
                        return (
                          <Planstable
                            key={index}
                            fetch={fetch}
                            id={info.id}
                            plan_name={info.plan_name}
                            amount={info.amount}
                            no_of_flashes={info.no_of_flashes}
                            isActive={info.isActive}
                            disableModal={showModal}
                          />
                        );
                      })
                    ) : (
                      <h2>No Plans found!</h2>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center">
            {/* <button className="btn w-25  btn-primary  mt-4"> Activate</button>    */}
          </div>
        </div>
      </div>
      {modal2 && <Plansconfirmation mount={setModal2} fetch={fetch} />}

      {modal && (
        <Disable
          id={dataID.id}
          name={dataID.name}
          fetch={fetch}
          mount={setModal}
        />
      )}
    </section>
  );
}

export default Manageplans;
