import React from 'react'

function GeneralTable(props) {
    return (
        <>
        <tr>
          <td> {props.user_mobile}
    
          </td>
          <td>{props.user_email}
           
          </td>

          <td> {props.name}
          
          </td>
          <td>
            {props.address}
          </td>
          </tr>
    
        </>
    )
}

export default GeneralTable
