import React from "react";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";

export default function Subscriptions(props) {
  console.log(props.data);

  return (
    <div
      className="tab-pane"
      id="contact"
      role="tabpanel"
      aria-labelledby="contact-tab"
    >
      {/* <div className="alert alert-danger">
        <i className="fe fe-info mr-1"></i> Your Subscriptions Expired.
      </div> */}

      <div className="row">
        <div className="col-12 col-md-6">
          {/* Card */}
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col">
                  {/* Title */}
                  <h6 className="text-uppercase text-muted mb-2">
                    Current Flashes
                  </h6>
                  {props.data.length > 0 ? (
                    <span className="h2 mb-0">
                      {props.data[0].no_of_deals}/mo |$
                      {props.data[0].plan_amount}
                    </span>
                  ) : (
                    <span className="h2 mb-0"> No active plans</span>
                  )}
                </div>
              </div>{" "}
              {/* / .row */}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="text-uppercase text-muted mb-2">
                    Consumed Flashes
                  </h6>
                  {props.data.length > 0 ? (
                    <span className="h2 mb-0">{props.data[0].used_sales}</span>
                  ) : (
                    <span className="h2 mb-0"> No active Plans</span>
                  )}
                </div>

                <div className="col-auto">
                  <div className="chart chart-sparkline">
                    <div className="chartjs-size-monitor">
                      <div className="chartjs-size-monitor-expand"></div>
                      <div className="chartjs-size-monitor-shrink"></div>
                    </div>
                    <canvas
                      className="chart-canvas chartjs-render-monitor"
                      id="sparklineChart"
                      style={{
                        display: "block",
                        width: "75px",
                        height: "35px",
                      }}
                      width={75}
                      height={35}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-header-title">Invoices</h4>
        </div>
        <div className="table-responsive">
          <table className="table table-sm table-nowrap card-table">
            <thead>
              <tr>
                <th>Invoice ID</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="font-size-base">
              {props.data.length > 0 ? (
                props.data.map((info, index) => {
                  return (
                    <tr key={index}>
                      <td>{info.invoice_id}</td>
                      <td>
                        <Moment parse="YYYY-MM-DD" format="MMM DD YYYY">
                          {info.date}
                        </Moment>
                      </td>
                      <td>{info.plan_amount}</td>
                      <td>
                        {info.status === "paid" ? (
                          <span className="badge badge-success text-dark ">
                            {" "}
                            Paid
                          </span>
                        ) : info.status === "trial" ? (
                          <span className="badge badge-danger text-warning">
                            Trial
                          </span>
                        ) : (
                          <span className="badge badge-danger text-dark">
                            Outstanding
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No records here</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        disableInitialCallback={true}
        pageCount={1}
        initialPage={0}
        activeLinkClassName={"page-link active"}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        containerClassName="pagination pagination-lg justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        disabledClassName={"disabled"}
        activeClassName="page-item active"
      ></ReactPaginate> */}
    </div>
  );
}
