import React, { useEffect } from "react";
import $ from 'jquery';

export default function ConfirmationModal(props) {
     
  
  
  function rejectConfirmed(){

      props.rejectfunc()
     window.$("#logoutModal").modal('hide')
    $(".modal-backdrop").remove();
    props.unload(false)
  }
  
  

  return (
      <div
        className="modal fade"
        data-keyboard="false"
        data-backdrop="static"
        id="logoutModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content text-center">
            {/* <div className="modal-header text-center">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div> */}
            <div className="modal-body">

           <h3>  {props.names}'s  application will be marked rejected. </h3>

            </div>
            <div className="modal-footer">
            <button type="button"  onClick={()=>rejectConfirmed()} className="btn btn-primary w-25">
                Yes,Confirm
              </button>

              <button
                type="button"
                className="btn btn-danger w-25"
                data-dismiss="modal"
                onClick={()=>props.unload(false)}
              >
                No,Cancel
              </button>
             
            </div>
          </div>
        </div>
      </div>

  );
}
