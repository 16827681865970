import React from "react";
import { getAPI } from "../../Utils/Api-Interface";
import { useEffect, useState } from "react";
import SpinnerLogin from "../../assets/Spinners/SpinnerLogin";
import ReactPaginate from "react-paginate";
import { store } from "react-notifications-component";
import $ from "jquery";

const trialMonthArray = [{ value: 1 }, { value: 2 }, { value: 3 }];

//for pagination
const PER_PAGE = 10;
//  console.log(PER_PAGE)
export default function Plansmodal(props) {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [plans, setPlans] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [checked, setChecked] = useState(null);
  //error will only get true when no token is present any server issues.
  const [error, setError] = useState(null);
  const [trial, setTrial] = useState(1);

  // on mount the API is fetched with a cleanup function.
  useEffect(() => {
    // console.log('plans have been mounted')
    const auth = localStorage.getItem("auth");

    // const source= axios.CancelToken.source();
    //console.info(source)
    getAPI(`/admin/plan_list`, auth)
      .then((res) => {
        if (res.data.data.length > 0) {
          console.log(res.data);
          setPlans(res.data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.warn(err.response.data.message);
        setError(err.response.status);
        setLoading(false);
      });

    return () => {
      console.log("setting the plnas state to 0");
      setPlans([]);
    };
  }, [props.bid]);

  //hanldes the pages on click and fetches new data
  const handlePageClick = (e) => {
    setCurrentPage(e.selected);
  };

  const handleCheck = (planId, bId) => {
    if (planId === checked) {
      setChecked(null);
    } else {
      setChecked(planId);
    }
  };

  //closing the modal will start a cleanup.
  const closeModal = () => {
    $(".modal-backdrop").remove();
    props.load(false);
  };

  // closes the modal and calls with a success message from the notification system
  const closeModalWithSuccess = () => {
    window.$("#modal2").modal("hide");
    $(".modal-backdrop").remove();
    props.load(false);
    props.success();
    // $('#modal2').on('hide.bs.modal', function (e) {
    //   // do something...
    //     console.log('success check scrollbar')
    //  props.load(false);
    // })
  };

  const submit = () => {
    const token = localStorage.getItem("auth");
    console.log("");
    setLoading2(true);
    getAPI(
      `/admin/approve_restaurants?businessId=${props.bid}&planId=${checked}&status=1&days=${trial}`,
      token
    )
      .then((res) => {
        setLoading2(false);
        closeModalWithSuccess();
      })

      .catch((err) => {
        console.log(err);

        setLoading2(false);

        store.addNotification({
          title: "Error",
          message: `Could not Complete Action. ${err.response.data}`,
          type: "danger",
          insert: "top",
          dismiss: {
            duration: 3000,
            pauseOnHover: true,
            onScreen: true,
          },
          container: "top-right",
          animationIn: ["animate__animated", "animate__bounceInDown"],
        });
      });
  };

  const handleTrial = (value) => {
    setTrial(value);
  };

  //for pagination
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(plans.length / PER_PAGE);

  return (
    <div
      className="modal fade fixed-right"
      data-backdrop="static"
      data-keyboard="false"
      id="modal2"
    >
      <div
        className="modal-dialog fixed-right modal-dialog-vertical"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              style={{ fontSize: "2.0rem" }}
              data-dismiss="modal"
              className="close"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="header">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-end">
                    <div className="col-12 p-0">
                      <h6 className="header-pretitle">Plans List</h6>
                      <h1 className="header-title"> {props.name}</h1>
                    </div>
                  </div>
                </div>
                <div className="table-responsive mt-1">
                  <table className="table table-sm card-table">
                    <thead>
                      <tr>
                        <th scope="col">Select</th>
                        <th scope="col">Plan Name</th>
                        {/* <th scope="col">Plan code</th>
                        <th scope="col"> Title </th> */}
                        <th scope="col">Amount </th>
                        <th colSpan="2"> No of flashes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr className="text-center">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <SpinnerLogin />
                          </td>
                        </tr>
                      ) : plans.length > 0 ? (
                        plans
                          .slice(offset, offset + PER_PAGE)
                          .map((info, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={checked === info.id}
                                    onChange={() =>
                                      handleCheck(info.id, props.bid)
                                    }
                                    name="id"
                                  ></input>
                                </td>
                                <td>
                                  {info.plan_name ? (
                                    info.plan_name
                                  ) : (
                                    <span style={{ color: "red" }}>N/A</span>
                                  )}
                                </td>
                                {/* <td>{info.plan_code}</td>
                                <td>{info.tittle}</td> */}
                                <td>{info.amount}</td>
                                <td>{info.no_of_flashes}</td>
                              </tr>
                            );
                          })
                      ) : error != null ? (
                        <tr>
                          <td>
                            Could not fetch plans records due to some error
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>No plans found for this restaurant </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {/* {!(loading||error)?
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    disableInitialCallback={"true"}
                    pageCount={pageCount}
                    initialPage={currentPage}
                    activeLinkClassName={"page-link active"}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    containerClassName="pagination pagination-lg justify-content-center"
                    onPageChange={handlePageClick}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    disabledClassName={"disabled"}
                    activeClassName="page-item active"
                  />
                    :null} */}
                </div>
                {console.log("trail check = ", trial)}
                <div className="d-flex justify-content-center mt-5">
                  <span style={{ margin: "1rem", fontWeight: "bold" }}>
                    Trial Month :{" "}
                  </span>
                  {trialMonthArray.map((item) => {
                    return (
                      <>
                        <input
                          key={item.value}
                          type="checkbox"
                          checked={item.value === trial}
                          onChange={() => handleTrial(item.value)}
                          name="trial"
                          id={`trial${item.value}`}
                          style={{
                            margin: "1rem",
                            marginRight: "5px",
                            marginTop: "20px",
                          }}
                        />
                        <label
                          htmlFor={`trial${item.value}`}
                          style={{ margin: "1rem", marginLeft: 0 }}
                        >
                          {item.value} month
                        </label>
                      </>
                    );
                  })}
                </div>

                <div className="d-flex justify-content-center">
                  {!(loading || error) ? (
                    <button
                      className="btn btn-lg w-50   btn-primary  mt-4"
                      onClick={() => submit()}
                      disabled={
                        checked === null || loading2 === true ? true : false
                      }
                    >
                      {loading2 ? <SpinnerLogin /> : "Activate Plan"}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
