import React from 'react';

export default function Spinners(){

    return(
        <div className=' mb-3 d-flex align-items-center justify-content-center' style={{height:'500px', backgroundColor:''}}>
            <div style={{height:'', backgroundColor:''}}>
            <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
        </div>

    )
}

