import React from "react";


export default function RestaurantDetails(props) {
 
    console.log(props)
  return (
    <div
      className="tab-pane fade show active"
      id="home"
      role="tabpanel"
      aria-labelledby="home-tab"
    >
      <div className="col-12 p-0">
        <h2 className="mb-0">Restaurant Information</h2>
        <hr className="my-4" />
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label> Restaurant Name </label>
            <input
              type="text"
              className="form-control text-black-70"
              value={props.name}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label> Address </label>
            <input
              type="text"
              className="form-control text-black-70"
              value={props.address}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group ">
            <label> Website </label>
            <input
              type="text"
              className="form-control text-black-70"
              value={props.data.url ? props.data.url : "N/A"}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label> Email </label>
            <input
              type="email"
              className="form-control text-black-70"
              value={props.owner_email}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label> Phone Number </label>
            <input
              type="text"
              className="form-control text-black-70"
              value={props.data.mobile ? props.data.mobile : "N/A"}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label> Cuisine Types </label>
            <div className="types">
             {Array.isArray(props.dishTypes)&&props.dishTypes.map((info,index)=>{
               return <span key={index} className=" badge badge-secondary "> {info}</span>
             })}
             </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 p-0">
          <h2 className="mb-0">Owner Information</h2>
          <hr className="my-4" />
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label> Name </label>
            <input
              type="text"
              className="form-control text-black-70"
              value={props.owner_name ? props.owner_name : "N/A"}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group ">
            <label> Phone Number </label>
            <input
              type="text"
              className="form-control text-black-70"
              value={props.owner_mobile ? props.owner_mobile : "N/A"}
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-md-12">
          <div className="form-group">
            <label> Email </label>
            <input
              type="email"
              className="form-control text-black-70"
              value={props.owner_email ? props.owner_email : "N/A"}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
}
