import React from 'react';
import {BrowserRouter as Router,
Switch,
Route,}from 'react-router-dom';  

import Restaurants from '../page/Restaurants'
import Setting from '../page/Setting';
import Logout from '../page/Logout';
import Manageplans from '../page/ManagePlans';
//import {useState,useEffect} from 'react';
import Privaterouterestaurant from './PrivateRoute/PrivateRouteRestaurant';
import Suggested from '../page/Suggested';
//import Privaterouteres from './PrivateRoute/PrivatRouteRes';

export default function Routes(props) {
   
    return (
       
     <Router>
      
        <Switch>
         
          <Route path="/manage">
            <Manageplans/>
          </Route>
          
          <Privaterouterestaurant path="/restaurants" component={Restaurants}/>
  

            <Privaterouterestaurant path="/suggestions" component={Suggested}></Privaterouterestaurant>
           <Privaterouterestaurant path="/setting" component={Setting}/>       
          

          <Route path="/">
            <Logout/>
          </Route>

        </Switch>
      
    </Router>
    )
}



