import { store } from "react-notifications-component";


    const notif=(title,message,type,time)=> {
    store.addNotification({
        title:title,
        message:message,
        type:type,
        insert: "top",
          dismiss: {
            duration: time?time:3000,
            pauseOnHover: true,
            onScreen: true,
          },
          container: "top-center",
          animationIn: ["animate__animated", "animate__bounceInDown"],
          animationOut: ["animate__animated", "animate__fadeOut"],

    })
}


export default notif;


