import React from 'react'
import {Redirect,Route} from 'react-router-dom';
// import {useEffect,useState} from 'react';

const Privaterouterestaurant=({component:Component,...rest})=> (
  
<Route
{...rest}
render={(props)=>(
   localStorage.getItem('auth')?<Component {...props}/>:<Redirect to='/'></Redirect>
)}



/>
  
   
 
    
)

export default Privaterouterestaurant;
